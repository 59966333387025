/*RESET*/
html,body{
  padding: 0;
  margin: 0;
  height:100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 460px;
  margin-left:auto;
  margin-right:auto;
}
p{
  margin:0;
    margin-block-start: 0;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


/*Layout */
#root {
min-height:100%;
position:relative;
}

header {
background:#FFC7E0;
  text-align:center;
  background-image: url("./asset/logo.png");
  background-position: bottom;
  background-size: 269px 148px;
  background-repeat: no-repeat;
  height:180px;
}

#smallbarapp {
  background:#FFC7E0;
  text-align:center;
  background-image: url("./asset/logo.png");
  background-position: -40px -15px;
  background-size: 269px 148px;
  background-repeat: no-repeat;
  height:52px;
}

main {
padding-bottom:56px; /* Height of the footer element */
  text-align:center;    
}
footer {
background:#ffffff;
width:100%;
height:45px;    /* Height of the footer element */
position:absolute;
bottom:0;
left:0;
  text-align:center;

  background-image: url("./asset/footer.png");
  background-position: center;
  background-size: 45px 46px;
  background-repeat: no-repeat;
}

a {
  font-size: 0.5em;
  display: block;   
}

#logo_img {
  max-width:269px;
  min-width:200px;
}

#footer_img {
  width:auto;
  height:45px;
}

/**/



/* butoane*/
.btn {
  width: auto;
  min-width: 100px;
  height: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;   
  padding: 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: none;
  margin-top: 24px;
  margin-bottom: 24px;
  box-shadow: 0.5px 0.5px 0.5px rgb(66, 66, 66);
  cursor: pointer;
  border-radius: 2px;
}

.btn:active {
  background-color: #787A80;
  box-shadow: 0 0 white;
  transform: translateY(0.5px);
  transform: translateX(0.5px);
  
}

.secondary-btn {
  background-color: #787A80;
  color: #ffffff;    
}
.secondary-btn:hover {
  background-color: #B30553;
  color: #ffffff;    
}

.primary-btn {
  background-color: #B30553;
  color: #ffffff;    
}
.primary-btn:hover {
  background-color: #B30553;
  color: #ffffff;    
}


/* toate imaginile vor fi centrate oriziontal in parinte */
img {
  display: block;
  margin-left: auto;
  margin-right: auto;    
  width: 100%;
  height:auto;
}

/*anunt*/
.anunt {     
   color: #FFC7E0;
   background-color: #787A80;
   max-width:80%;
   margin-left: auto;
   margin-right: auto;
   transform: rotate(-9deg);
   margin-top: -24px;
   margin-bottom: 24px;
   font-size: 2em;
   font-style: italic;     
   font-weight: bold;
   box-shadow:1px 1px dimgray;
   padding-top: 20px;
   padding-bottom: 20px;
   font-family: 'Tempus Sans ITC';
}

.location {
  font-family: 'Tempus Sans ITC';
  font-weight: bold;
  font-size: 2em;
}

/*text*/
.info {
   color: #787A80;
   font-weight: bold;
   font-size: 1em;    
   text-align:center;
   margin-block-start: 0em;
   margin-block-end: 0em;
}

.ciclistName {
  color: #930041;
  font-weight: bold;
  font-size: 1.8em;    
  text-align:center;
  margin-block-start: 0.3em;
  margin-block-end: 0em;
}

.left-text {
  text-align:left;
  padding-left: 20px;        
  float: left;
}
.right-text {
  text-align:right;
  padding-right: 20px;    
  float: right;
}

.eticheta {
  color: #787A80;
  font-weight: normal;
  font-size: 0.5em;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  
}

.suplimentar {
  color: #B30553;
  font-weight: bold;
  font-size: 0.5em;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  cursor: pointer;
}


.switch {
  margin:10px;
}

/*specific*/

.presence {
  clear:both;
  overflow:auto;
}


form>div{
  width: auto;
  margin-left:0px;
  text-align: left;
  margin-top: 2px;
}
form>div>label{
  position: absolute;
  width: 100px;
  text-align: left;
  margin: 4px;
  padding-left:10px;  
  padding-top:3px;  
  font-size: 0.6em;
  color: #808080;
}

form>div>input,#error{
  margin: 4px;
  margin-left: 4px;
  margin-right: 4px;
  width: calc(100% - 10px - 3px - 2px - 8px);   

  height:28px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
   border-radius: 5px;
   border: 1.5px solid #808080;
   color: #474646;
   padding-left: 10px;
   padding-right: 2px;
   padding-top:12px;
   padding-bottom:0px;
}

#error {
  border-color:  #ffa3cd;
  
  background-color: #ffa3cd;
}

form>div>select{
  margin: 4px;
  margin-left: 4px;
  margin-right: 4px;
  width: calc(100% - 8px);   

  height:43px;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
   border-radius: 5px;
   border: 1.5px solid #808080;
   color: #474646;
   padding-left: 10px;
   padding-right: 2px;
   padding-top:12px;
   padding-bottom:0px;
}


form>div>input:focus{
  outline: none;
  border-radius: 5px;
  border:1.5px solid #808080;
}

form>div>select:focus{
  outline: none;
  border-radius: 5px;
  border:1.5px solid #808080;
}

.athleteCard{
  border: 1.5px solid #E2E2E2;
  padding: 10px;
  padding-bottom: 0;
  padding-top: 20px;
  border-radius: 5px;
  
  margin-top: 4px;
  margin-bottom:4px;
  margin-left: 0;
  margin-right: 0;

}

#backdrop {
  position:fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  

  left:0;
  right:0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  top:0;
}

#popup {
  height:400px;
  width:300px;
  z-index: 100;
  background-color: white;
  border-radius: 3px;
  position:fixed;
  margin-left: auto;
  margin-right: auto;

  left:0;
  right:0;


  top: 50%;
  margin-top: -200px;
 
  opacity:1;
}


.input_error{
  border-color:red;
}

.input_error:focus{
  border-color:red;
}

.label_error{
  padding-left: 10px;
  margin-bottom: 14px;
  font-size: 0.6em;
  color:red;
}

.trainingCard {
  border: 1.5px solid #b9b9b9;
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 0;
  border-radius: 5px;
  
  margin-top: 4px;
  margin-bottom:4px;
  margin-left: 0;
  margin-right: 0;
}

.separator {
  width: calc(100%-40px);
  height:1px;
  margin: 20px;
  margin-top: 60px;
  padding: 0;
  border: 0;
  background-color: black;
}


form>div>input[type="file"] {
  height:"auto";
  border: 0;  
}

form>div>input[type="file"]::before {
  height:"auto";
  border: 0;  
  content:"Select a file..."
}

form>div>input[type="file"]:focus {
  height:"auto";
  border: 0;
}
form>div>input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}

.input {
  margin: 4px;
  margin-left: 4px;
  margin-right: 4px;
  width: calc(100% - 10px - 3px - 10px - 8px);   


  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
   border-radius: 5px;
   border: 1.5px solid #808080;
   color: #474646;
   padding-left: 10px;
   padding-right: 10px;
   padding-top:12px;
   padding-bottom:0px;
}

.photoLabel {
    margin-top: -14px;
    margin-left: -11px;
}

#profile_photo {
  margin:0;
  padding:0;
  margin-top: 15px;
}



.label-heading {
  text-align: left;
}

.label-item .clear {
  clear:both;
}

.label-item .value {
  float: right;
}
.label-item .description {
  text-align: left;
  float: left;
  width: 75%;
}
.label-item {
  list-style: none;
  display: list-item;
  border-top: 1px solid #f0f0f5;
}


.btnConfirmati {
  font-size: 1em;
  
}

table {
  border: 1px solid black;
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0px;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 0px;
}

td {
  border: 1px solid black;
}

.linkFooter{
  font-size: 1em;
  display: flex;
  margin-right: 30px;
}